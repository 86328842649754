document.addEventListener("DOMContentLoaded", function(){
  document.querySelector("body").addEventListener("click", function(e) {
	  if(e.target && e.target.id == "subscription_is_gift") {
  		document.getElementById('email_gifted_wrapper').classList.toggle("hidden");
  	}
  });

  var acceptCGV = document.getElementById('accept_cgv');
  if (acceptCGV) {
    acceptCGV.addEventListener("change", function() {
      document.getElementById('pay').disabled = !this.checked;
    });
  }
});
